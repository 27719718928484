import axios from "axios"

export const getFindingsDetails = (idExam) => {
  const url = `${process.env.VUE_APP_API_URL}/exam/${idExam}/get_alt_json/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

export const deleteFinding = (idFiding) => {
  const url = `${process.env.VUE_APP_API_URL}/alt/${idFiding}/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.delete(url, config)
}

export const sendObservation = (idFiding, observation, idExam) => {
  const url = `${process.env.VUE_APP_API_URL}/alt/${idFiding}/`
  const token = localStorage.getItem("jwtToken")
  const userId = localStorage.getItem("userId")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  const data = {
    observacao: observation,
    id_exam: idExam,
    user: userId
  }
  return axios.put(url, data,config)
}