import axios from "axios"

const getExamDetails = (examId) => {
  const url = `${process.env.VUE_APP_API_URL}/pat/${examId}/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

//requisição para enviar dente ausente
const postAltSingle = (requestData) => {
  const url = `${process.env.VUE_APP_API_URL}/alt/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.post(url, requestData, config)
}

//envia alterações multiplas
const postAlteracao = (data) => {
  const url = `${process.env.VUE_APP_API_URL}/alt/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }

  // Verifica se data é válido antes de enviar
  if (data.length === 0) {
    console.warn("Os dados enviados estão vazios ou inválidos.")
    return Promise.reject("Os dados enviados estão vazios ou inválidos.")
  }

  // Envia cada item de data em uma requisição separada
  const promises = data.map((item) =>
    axios.post(url, item, config)
      .then((response) => {
        console.log('Sucesso:', response.data)
        return response.data
      })
      .catch((error) => {
        console.error('Erro ao enviar item:', item, error)
        return null 
      })
  )

  // Retorna uma Promise que resolve quando todas as requisições terminarem
  return Promise.all(promises)
}

const changeTypeTeeth = (examId, requestData) => {
  const url = `${process.env.VUE_APP_API_URL}/pat/${examId}/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }
  return axios.put(url, requestData, config)
}

export { getExamDetails, postAltSingle, postAlteracao, changeTypeTeeth }