<template>
  <v-container class="mx-6">
    <snackbarNotification ref="snackbarRef" />
    <v-row class="mx-6">
      <v-col cols="12" md="6" sm="6">
        <v-card width="100%">
          <v-card-title class="text-h4-base font-weight-bold mb-6"
            >Envio de Exames</v-card-title
          >
          <v-card-text>
            <v-form @submit.prevent="submitForm">
              <v-row>
                <v-col cols="8">
                  <div class="mx-2">
                    <v-text-field
                      v-model="nm_identifier"
                      label="Identificador"
                      variant="outlined"
                      clearable
                    ></v-text-field>
                    <v-file-input
                      v-model="arquivo"
                      label="Escolher Arquivo"
                      accept=".jpg, .jpeg, .png"
                      @change="previewImage"
                      @click:clear="cancelarEnvio"
                      variant="outlined"
                    ></v-file-input>
                  </div>
                </v-col>
                <img
                  id="image"
                  v-if="arquivo"
                  :src="previewUrl"
                  ref="cropperImage"
                  @load="initCropper"
                />
                <v-col cols="8">
                  <div class="mb-8">
                    <v-btn
                      @click="uploadFile"
                      :loading="uploading"
                      color="primary"
                      :disabled="uploading || !arquivo"
                    >
                      <template v-if="!uploading"> Enviar </template>
                      <template v-else>
                        <v-progress-linear
                          :value="knowledge"
                          color="white"
                          height="2"
                          indeterminate
                        ></v-progress-linear>
                        Aguarde...
                      </template>
                    </v-btn>
                    <v-btn @click="cancelarEnvio" color="error" class="mx-4">
                      Cancelar
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card>
          <v-card-title class="text-h4-base font-weight-bold mb-6">
            Fila de Envios
            <span v-if="uploadStarted">
              | Pendente: {{ pendingCount }}</span
            ></v-card-title
          >
          <v-card-text>
            <div v-if="uploadStarted">
              <v-progress-linear
                v-model="knowledge"
                height="25"
                color="teal"
              ></v-progress-linear>
              <strong>{{ Math.ceil(knowledge) }}%</strong>
              <br />
              <span v-if="knowledge < 100">
                {{ knowledge === 0 ? "Enviando..." : "Preparando..." }}
              </span>
            </div>
          </v-card-text>
          <div v-if="uploadedFiles">
            <v-list-item v-for="(file, index) in uploadedFiles" :key="index">
              <div>
                <v-list-item-title class="mb-2">
                  <v-chip
                    color="light-green-darken-2"
                    prepend-icon="mdi-check-circle-outline"
                  >
                    {{ file.status === "enviado" ? "Concluído " : "" }}
                  </v-chip>
                  - {{ file.name }}
                </v-list-item-title>
                <a class="list-link" href="/list">Ir para a lista</a>
              </div>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import "cropperjs/dist/cropper.css"
  import Cropper from "cropperjs"
  import axios from "axios"
  import snackbarNotification from "./snackbarNotification.vue"

  export default {
    components: {
      snackbarNotification,
    },
    data() {
      return {
        nm_identifier: "",
        arquivo: null,
        previewUrl: null,
        cropper: null,
        knowledge: 0,
        uploading: false,
        uploadStarted: false,
        pendingCount: 1,
        uploadStatus: "",
        uploadedFiles: [],
      }
    },
    methods: {
      async uploadFile() {
        try {
          this.uploadStatus = ""
          this.uploadStarted = true
          this.uploading = true
          this.knowledge = 0
          const token = localStorage.getItem("jwtToken")

          if (!this.cropper) {
            // If Cropper instance is not initialized, show an error
            this.$refs.snackbarRef.showError(
              "Por favor, selecione uma área na imagem."
            )
            return
          }

          const croppedData = this.cropper
            .getCroppedCanvas()
            .toDataURL("image/jpeg")

          let fileName = this.nm_identifier || this.arquivo[0].name
          if (this.nm_identifier === "") {
            fileName = fileName.replace(/\.[^/.]+$/, "")
          }

          const fileNameFull = this.arquivo[0].name
          const nm_source = fileNameFull
          const nm_description = fileName
          const nm_report = fileNameFull.replace(/\.[^/.]+$/, ".pdf")

          const csrfmiddlewaretoken = localStorage.getItem("jwtToken")
          const id_user = localStorage.getItem("userId")
          const data = new Date()
          const data_format = data.toISOString().split("T")[0]
          const sg_status = ""
          const algo_version = 1

          const formData = new FormData()
          formData.append("csrfmiddlewaretoken", csrfmiddlewaretoken)
          formData.append("user", id_user)
          formData.append("nm_source", nm_source)
          formData.append("nm_report", nm_report)
          formData.append("algo_version", algo_version)
          formData.append("sg_status", sg_status)
          formData.append("nm_description", nm_description)
          formData.append("dt_modified", data_format)
          formData.append("dt_received", data_format)

          // Convert the base64 cropped data to a Blob
          const croppedBlob = await fetch(croppedData).then((res) => res.blob())

          formData.append("panoramica", croppedBlob, nm_source)

          const postPromise = new Promise((resolve, reject) => {
            let currentProgress = 0
            const progressInterval = setInterval(() => {
              if (currentProgress < 100) {
                currentProgress += Math.random() * 10
                currentProgress = Math.min(currentProgress, 100)
                this.knowledge = currentProgress
              } else {
                clearInterval(progressInterval)
                resolve()
              }
            }, 200)

            axios
              .post(
                `${process.env.VUE_APP_API_URL}/exam/`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((response) => {
                clearInterval(progressInterval)
                resolve(response)
              })
              .catch((error) => {
                clearInterval(progressInterval)
                reject(error)
              })
          })

          // Wait for a delay of 5 seconds using setTimeout
          await Promise.race([
            postPromise,
            new Promise((resolve) => setTimeout(resolve, 5000)),
          ])

          // Show success message
          this.$refs.snackbarRef.showSuccess("Upload bem-sucedido!")

          // Reset progress after completion (success or failure)
          this.uploading = false
          this.uploadStarted = false
          this.knowledge = 0
          this.uploadStatus = "enviado"
          this.uploadedFiles.unshift({
            name: this.nm_identifier || this.arquivo[0].name,
            status: this.uploadStatus,
          })
          this.cancelarEnvio()
        } catch (error) {
          // Handle the error as needed
          this.$refs.snackbarRef.showError("Erro ao enviar o arquivo!")
          this.uploading = false
          this.uploadStarted = false
          this.knowledge = 0
          this.uploadStatus = "Falha"
        }
      },
      submitForm() {
        console.log(
          "Enviando exames:",
          this.nome,
          this.descricao,
          this.nomeImagem,
          this.arquivo
        )
      },
      previewImage() {
        if (this.arquivo && this.arquivo.length > 0) {
          const reader = new FileReader()
          reader.onload = (e) => {
            this.previewUrl = e.target.result
          }
          reader.readAsDataURL(this.arquivo[0])
        }
      },
      initCropper() {
        // If Cropper has already been initialized, destroy it before recreating it
        if (this.cropper) {
          this.cropper.destroy()
        }

        // Use Ref to access the img element
        const imageElement = this.$refs.cropperImage

        if (imageElement.complete) {
          this.initializeCropper(imageElement)
        } else {
          imageElement.onload = () => {
            this.initializeCropper(imageElement)
          }
        }
      },
      initializeCropper(imageElement) {
        // Init Cropper.js
        this.cropper = new Cropper(imageElement, {
          viewMode: 1,
          aspectRatio: 0,
          autoCropArea: 0.8,
        })
      },
      cancelarEnvio() {
        this.nm_identifier = ""
        this.arquivo = null
        this.previewUrl = null

        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = null
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
  .cropper-container {
    width: 100% !important;
    height: 400px !important;
  }

  .cropper-view-box,
  .cropper-face {
    border-radius: 0 !important;
  }

  img {
    display: block;
    max-width: 80%;
  }

  .custom-progress {
    display: flex;
    align-items: center;
  }

  .custom-progress v-progress-linear {
    flex: 1;
    margin-right: 10px;
  }

  .list-link {
    text-decoration: none;
    background: #00a608;
    color: #fff;
    padding: .5rem .75rem;
    border-radius: .5rem;
    margin: 1rem 0;
    display: inline-block;
  }
</style>
