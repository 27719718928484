import axios from "axios"

const getMapa = () => {
  const url = `${process.env.VUE_APP_API_URL}/mapa/`
  const token = localStorage.getItem("jwtToken")
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  }
  return axios.get(url, config)
}

export { getMapa }