<!-- eslint-disable no-inner-declarations -->
<template>
  <v-container fluid class="mx-6">
    <snackbarNotification ref="snackbarRef" />

    <generic-dialog
      :dialogVisible="editDialog || editDialogDental || editDialogAnatomico"
      :title="'Editar Item'"
      ref="dialogEditar"
      @save="salvarEdicao"
      @cancel="cancelarEdicao"
    >
      <v-select
        v-if="mapaData"
        v-model="editedItem.id_alteracao"
        :items="mapaData"
        item-title="nm_alteracao"
        item-value="id_alteracao"
        label="Alteração"
      ></v-select>
      <v-select
        v-model="editedItem.alteracaoFace"
        label="Face"
        :items="faceDental"
        item-title="name"
        item-value="id"
      ></v-select>
      <v-text-field
        v-model="editedItem.num_dente"
        label="Número do Dente"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="editedItemObservacao"
        label="Observação"
        outlined
      ></v-text-field>
    </generic-dialog>
    <generic-dialog
      :dialogVisible="seuValorDeDialogVisible"
      :title="'Adicionar achado'"
      @save="enviarDados"
      @cancel="cancelarAdicao"
      ref="dialogAdicao"
    >
      <div v-if="tipoLista === 'ausente'">
        <v-text-field
          v-model="campoDente"
          placeholder="Número do dente"
        ></v-text-field>
      </div>

      <div v-else-if="tipoLista === 'dental'">
        <v-text-field
          v-model="campoDente"
          placeholder="Número do dente"
        ></v-text-field>
        <v-select
          v-model="addAchado"
          :items="listDental"
          item-title="nm_alteracao"
          item-value="id_alteracao"
          label="Alteração"
        ></v-select>
      </div>

      <div v-else-if="tipoLista === 'anatomico'">
        <v-select
          v-model="addAchado"
          :items="listAnatomic"
          item-title="nm_alteracao"
          item-value="id_alteracao"
          label="Alteração"
        ></v-select>
      </div>
    </generic-dialog>
    <v-row no-gutters>
      <v-col>
        <v-sheet class="pa-2 ma-2">
          <div class="d-flex justify-start">
            <p class="text-h4-base font-weight-bold">Descrição:</p>
            <p class="mx-2 text-h4-base font-weight-bold">{{ imageName }}</p>
          </div>
          <p class="mt-2 text-h5-base">Versão do algoritmo: 1.0</p>
        </v-sheet>
      </v-col>
      <v-col class="d-flex aling-center justify-end">
        <v-sheet class="pa-2 ma-2">
          <v-btn @click="exportarPdf" color="primary" class="mx-2">
            <!-- <v-progress-circular
              v-if="loading"
              indeterminate
              size="20"Dental
              class="mr-2"
            ></v-progress-circular> -->
            Exportar PDF
          </v-btn>
          <v-btn @click="compartilhar" color="primary">Compartilhamento</v-btn>
        </v-sheet>
      </v-col>
    </v-row>
    <v-alert
      icon="$info"
      text="Ferramenta de auxilio para o dentista e não pode ser usada como laudo
        médico"
      type="info"
      variant="tonal"
    ></v-alert>

    <v-card elevation="16" class="mt-4" width="100%">
      <v-row no-gutters>
        <v-col>
          <v-sheet class="pa-2 ma-2">
            <div class="">
              <div>
                <p class="mt-2 text-h3-base font-weight-bold">Paciente</p>
              </div>
              <div class="mt-12 pb-4">
                <div>
                  <strong class="text-h5-base font-weight-bold">Idade: </strong>
                  <span
                    class="text-h5-base font-weight-regular"
                    v-if="!editando"
                    >{{ idade }}</span
                  ><v-text-field v-else v-model="idadeEditado"></v-text-field>
                </div>
                <div>
                  <strong class="text-h5-base font-weight-bold">Sexo: </strong>
                  <span
                    class="text-h5-base font-weight-regular"
                    v-if="!editando"
                    >{{ sexo }}</span
                  >
                  <v-select
                    v-else
                    v-model="sexoEditado"
                    :items="opcoesSexo"
                    label="Selecione o sexo"
                    item-title="text"
                    item-value="value"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </div>
                <div>
                  <strong class="text-h5-base font-weight-bold"
                    >Tipo de Dentição:
                  </strong>
                  <span
                    class="text-h5-base font-weight-regular"
                    v-if="!editando"
                    >{{ tipoDenticao }}</span
                  >
                  <v-select
                    v-else
                    v-model="tipoDenticaoEditado"
                    :items="opcoesTipoDenticao"
                    item-title="text"
                    item-value="value"
                    label="Selecione"
                    persistent-hint
                    return-object
                    single-line
                  ></v-select>
                </div>
                <div>
                  <strong class="text-h5-base font-weight-bold"
                    >Data do Envio:
                  </strong>
                  <span class="text-h5-base font-weight-regular">{{
                    dataExame
                  }}</span>
                </div>
              </div>
            </div>
          </v-sheet>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <v-sheet class="pa-2 ma-2">
            <v-btn @click="editarPaciente" color="primary" class="mx-2">
              {{ editando ? "Salvar" : "Editar" }}
            </v-btn>
            <v-btn
              v-if="editando"
              @click="cancelarEdicaoPat"
              color="error"
              class="mx-2"
            >
              Cancelar
            </v-btn>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>

    <v-card elevation="16" class="mt-4" width="100%">
      <caroseulImage :images="images" />
    </v-card>

    <v-row class="mb-6">
      <v-col cols="8">
        <v-card elevation="16" class="mt-4" height="100%">
          <p class="text-h3-base font-weight-bold mt-4 mb-4 mx-4">
            Odontograma
          </p>
          <div style="height: 600px">
            <div
              style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 14px;
                color: black;
              "
            >
              <v-col
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <!-- Loop linha superior -->
                <div
                  v-for="number in [
                    18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27,
                    28,
                  ]"
                  :key="number"
                  style="margin-right: 15px; position: relative"
                >
                  <!-- container imagem/retangulo -->
                  <div>
                    <!-- imagem X-->
                    <img
                      :src="
                        hasAlteration(number)
                          ? require('@/assets/icon-x.svg')
                          : getImagePath(number)
                      "
                      alt="Dente {{ number }}"
                      style="width: 100%; height: 100%; margin-bottom: 5px"
                    />
                    <!-- Aplica classe retangulo se tem alteração -->
                    <div
                      :style="getAlterationColor(number)"
                      :class="{
                        'alteration-rectangle': hasAlterationDental(number),
                      }"
                    ></div>
                    {{ number }}
                  </div>
                </div>
              </v-col>

              <!-- Loop linha inferior -->
              <v-col
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  width: 100%;
                "
              >
                <div
                  v-for="number in [
                    48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37,
                    38,
                  ]"
                  :key="number"
                  style="margin-right: 15px; position: relative"
                >
                  <!-- container imagem/retangulo -->
                  <div>
                    <!-- imagem X-->
                    <img
                      :src="
                        hasAlteration(number)
                          ? require('@/assets/icon-x.svg')
                          : getImagePath(number)
                      "
                      alt="Dente {{ number }}"
                      style="width: 150%; height: 150%; margin-top: 5px"
                    />
                    <!-- Aplica classe retangulo se tem alteração -->
                    <div
                      :style="getAlterationColor(number)"
                      :class="{
                        'alteration-rectangle': hasAlterationDental(number),
                      }"
                    ></div>
                    {{ number }}
                  </div>
                </div>
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <!-- Novo v-card para "Ausente/Extranumerário" -->
        <v-card elevation="16" class="mt-4" height="100%">
          <v-card-title to class="text-h5-base font-weight-bold mt-4"
            >Edição de Ausente</v-card-title
          >
          <!-- <v-card-title class="text-h5-base font-weight-bold mt-4"
            >Ausente</v-card-title
          > -->

          <v-list
            style="
              height: 620px;
              padding: 20px;
              border: 2px solid silver;
              margin: 15px;
            "
          >
            <div
              v-if="
                filteredMapaDataAusente && filteredMapaDataAusente.length > 0
              "
            >
              <div class="d-flex justify-center align-center mx-4 mb-4">
                Adicionar dente ausente
                <v-text-field
                  v-model="campoDente"
                  class="hover-click-effect"
                  style="width: 200px"
                  variant="outlined"
                  placeholder="Nº Dente"
                  clearable
                  @keydown.enter="enviarDados"
                ></v-text-field>
              </div>
              <!-- Ordena a lista pelo número do dente -->
              <v-list-item
                v-for="item in filteredMapaDataAusente"
                :key="item.id"
              >
                <div
                  v-if="getNomeAlteracao(item.id_alteracao) === 'Dente ausente'"
                >
                  <v-list-item-title>
                    <div class="d-flex align-center justify-space-between">
                      <div>
                        <p>{{ `Dente: ${item.num_dente}` }}</p>
                      </div>
                      <div>
                        <v-list-item-action>
                          <v-icon @click="removerItem(item)">mdi-delete</v-icon>
                        </v-list-item-action>
                      </div>
                    </div>
                    <v-divider
                      class="border-opacity-100 mt-2"
                      color="blue"
                    ></v-divider>
                  </v-list-item-title>
                </div>
              </v-list-item>
            </div>
            <v-list-item v-else>
              <div>Nenhum item encontrado.</div>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Novo v-card para "Detalhamento dos achados" -->
    <v-card elevation="16" class="mt-4" style="width: 100%">
      <!-- Seção "Tipo de Dentição" -->
      <v-card-title class="text-h4-base font-weight-bold mt-4 mb-4 mx-6"
        >Tipo de Dentição (com ausentes e extranum.)
      </v-card-title>

      <!-- <span class="mx-4 text-h5-base font-weight-bold">Ausente</span> -->
      <v-card-text>
        <v-list
          style="
            height: 150px;
            padding: 20px;
            border: 2px solid silver;
            margin: 20px;
          "
        >
          <div
            v-if="dentesAusentesAgrupados && dentesAusentesAgrupados.length > 0"
          >
            <v-list-item>
              <div>
                <v-list-item-title>
                  <div class="d-flex align-center justify-space-between">
                    <div class="d-flex">
                      <span class="font-weight-regular"
                        >{{ tipoDenticao }} - ({{
                          dentesAusentesAgrupados.length
                        }}) Elementos ausentes:
                        {{ dentesAusentesAgrupados.join(", ") }}
                      </span>
                    </div>
                  </div>
                  <v-divider
                    class="border-opacity-100 mt-4 mb-4"
                    color="blue"
                  ></v-divider>
                  <span
                    v-if="filteredDentesExtranumerarios.length > 0"
                    class="font-weight-regular"
                  >
                    ({{ filteredDentesExtranumerarios.length }}) Elemento
                    Extranumerário (próximo ao dente)
                    {{ filteredDentesExtranumerarios.join(", ") }}
                  </span>
                  <span v-else>Nenhum elemento Extranumerário.</span>
                </v-list-item-title>
              </div>
            </v-list-item>
          </div>
          <v-list-item v-else height="100px">
            <div>Nenhum item encontrado.</div>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-title class="text-h4-base font-weight-bold mt-4 mb-4 mx-6"
        >Detalhamento dos achados</v-card-title
      >

      <!-- Seção "Dental" -->
      <div class="d-flex flex-wrap justify-start align-center">
        <span class="mx-10 text-h5-base font-weight-bold">Dental</span>

        <!-- Número do Dente -->
        <v-text-field
          v-model="campoDente"
          label="Número do Dente"
          outlined
          type="number"
          class="mx-2"
          width="500px"
        ></v-text-field>

        <!-- Alteração -->
        <v-select
          v-if="mapaData"
          v-model="alteracao"
          :items="mapaData"
          item-title="nm_alteracao"
          item-value="id_alteracao"
          label="Alteração"
          class="mx-2 flex-grow-1"
        ></v-select>

        <!-- Face -->
        <v-select
          v-model="alteracaoFace"
          label="Face"
          class="mx-2"
          :items="faceDental"
          item-title="name"
          item-value="id"
        ></v-select>

        <!-- Observação -->
        <v-text-field
          v-model="observacao"
          label="Observação"
          outlined
          class="mx-2 flex-grow-1"
        ></v-text-field>

        <!-- Botões -->
        <v-btn
          color="primary"
          v-if="!loading"
          @click="enviarDental"
          class="mx-2"
        >
          Salvar
        </v-btn>

        <v-btn
          variant="tonal"
          @click="cancelChanges"
          v-if="!loading"
          class="mx-2"
        >
          Cancelar
        </v-btn>
      </div>
      <v-card-text>
        <v-list
          style="
            height: 250px;
            padding: 20px;
            border: 2px solid silver;
            margin: 20px;
          "
        >
          <div v-if="filteredDetailsDental && filteredDetailsDental.length > 0">
            <!-- Ordena a lista pelo número do dente -->
            <v-list-item
              v-for="item in sortedFilteredDetailsDental"
              :key="item.id"
            >
              <div>
                <v-list-item-title>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p>
                        {{ `Região do dente ${item.num_dente}:` }}
                        {{ `${getNomeAlteracao(item.id_alteracao)}` }}
                        {{
                          getVariacaoAlteracaoFormatted(item.variacao_alteracao)
                        }}
                      </p>
                      <p v-if="item.observacao !== null">
                        {{
                          `Observação: ${
                            item.observacao !== null ? item.observacao : ""
                          }`
                        }}
                      </p>
                    </div>
                    <div>
                      <v-list-item-action>
                        <v-icon @click="editarItem(item)" class="mx-4"
                          >mdi-pencil</v-icon
                        >
                        <v-icon @click="removerItem(item)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </div>
                  </div>
                  <v-divider
                    class="border-opacity-100 mt-4"
                    color="blue"
                  ></v-divider>
                </v-list-item-title>
              </div>
            </v-list-item>
          </div>
          <v-list-item v-else height="100px">
            <div>Nenhum item encontrado.</div>
          </v-list-item>
        </v-list>
      </v-card-text>

      <!-- Seção "Anatômico" -->
      <span class="text-h5-base font-weight-bold mx-10">Anatômico</span
      ><v-btn variant="text" @click="abrirDialogAdicao('anatomico')"
        ><span class="text-capitalize text-body-2-base mx-2"
          >Nova alteração</span
        ><v-icon>mdi-plus-circle</v-icon></v-btn
      >
      <v-card-text>
        <v-list
          style="
            height: 250px;
            padding: 20px;
            border: 2px solid silver;
            margin: 20px;
          "
        >
          <div
            v-if="
              filteredMapaDataAnatomico && filteredMapaDataAnatomico.length > 0
            "
          >
            <!-- Ordena a lista pelo número do dente -->
            <v-list-item
              v-for="item in filteredMapaDataAnatomico"
              :key="item.id"
            >
              <div>
                <v-list-item-title>
                  <div class="d-flex align-center justify-space-between">
                    <div>
                      <p>{{ `${getNomeAlteracao(item.id_alteracao)}` }}</p>
                      <p v-if="item.observacao !== null">
                        {{
                          `Observação: ${
                            item.observacao !== null ? item.observacao : ""
                          }`
                        }}
                      </p>
                    </div>
                    <div>
                      <v-list-item-action>
                        <v-icon @click="editarItem(item)" class="mx-4"
                          >mdi-pencil</v-icon
                        >
                        <v-icon @click="removerItem(item)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </div>
                  </div>
                  <v-divider
                    class="border-opacity-100 mt-4"
                    color="blue"
                  ></v-divider>
                </v-list-item-title>
              </div>
            </v-list-item>
          </div>
          <v-list-item v-else>
            <div>Nenhum item encontrado.</div>
          </v-list-item>
        </v-list>
      </v-card-text>
      <div class="btn-reviewd-wrap">
      <v-btn
        color="primary"
        class="mx-2"
        @click="updateStatus"
      >
        Concluir e Assinar
      </v-btn>
    </div>
    </v-card>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { format, addHours } from "date-fns"
  import { fabric } from "fabric"
  import caroseulImage from "./caroseulImage.vue"
  import snackbarNotification from "./snackbarNotification.vue"
  import GenericDialog from "./genericDialog.vue"
  import { saveAs } from "file-saver"

  export default {
    name: "ExamDetailsForm",
    components: {
      caroseulImage,
      snackbarNotification,
      GenericDialog,
    },
    data() {
      return {
        imageName: "",
        idade: "25 anos",
        sexo: "Masculino",
        tipoDenticao: "Permanente",
        dataExame: "01/11/2023",
        idadeEditado: "",
        sexoEditado: "",
        tipoDenticaoEditado: "",
        dataExameEditado: "",
        editando: false,
        canvas: null,
        isMoving: false,
        activeObject: null,
        viewBoxWidth: 600,
        viewBoxHeight: 400,
        dialog: false,
        textToAdd: "",
        textColor: "#000000",
        textSize: 16,
        user: "",
        examDetails: {
          user: "",
          idade: "",
          sexo: "",
          tipoDenticao: "",
          dataExame: "",
        },
        opcoesSexo: [
          { text: "Feminino", value: 1 },
          { text: "Masculino", value: 2 },
        ],
        opcoesTipoDenticao: [
          { text: "Dentição Permanente", value: 1 },
          { text: "Dentição Mista", value: 2 },
          { text: "Dentição Decídua", value: 3 },
        ],
        campoDente: "",
        addAchado: "",
        detailsByIdExam: "",
        objDetails: [],
        denteSelecionado: null,
        editDialog: false,
        editDialogDental: false,
        editDialogAnatomico: false,
        editedItem: null,
        editedItemObservacao: null,
        mapaData: null,
        listAusent: null,
        listDental: null,
        listAnatomic: null,
        filteredMapaDataDental: null,
        filteredDetailsDental: "",
        filteredMapaDataAusente: null,
        filteredMapaDataAusenteAgrupado: null,
        dentesAusentesAgrupados: null,
        filteredMapaDataAnatomico: null,
        filteredDetailsAnatomico: "",
        filteredDentesExtranumerarios: null,
        images: [],
        seuValorDeDialogVisible: false,
        tipoLista: "",
        imageOpen: null,
        loading: false,
        numeroDente: "",
        alteracao: null,
        alteracaoFace: null,
        observacao: "",
        faceDental: [
          { id: 1, name: "Mesial" },
          { id: 2, name: "Distal" },
          { id: 3, name: "Oclusal/incisal" },
          { id: 4, name: "Cervical" },
          { id: 5, name: "Radicular" },
        ],
        classColors: {
          21: "blue",
          51: "light-blue",
          40: "green",
          41: "red",
          42: "grey",
          43: "orange",
          44: "purple",
          45: "pink",
        },
      }
    },
    mounted() {
      this.loadImages()
      // Inicializa o canvas do Fabric.js
      this.canvas = new fabric.Canvas(this.$refs.canvas, {
        selection: false,
        backgroundColor: "silver",
        width: 800,
        height: 600,
      })

      this.loadImage()
      this.initializeHistory()
      ;(async () => {
        try {
          await this.fetchMapaData()
          this.getAchadosDetails()
          this.fetchExamDetails()
        } catch (error) {
          console.error("Erro durante a montagem:", error)
        }
      })()
    },
    computed: {
      sortedDetailsByIdExam() {
        return this.objDetails.slice().sort((a, b) => a.num_dente - b.num_dente)
      },
      formattedListAusent() {
        return this.listAusent.map((item) => ({
          tipo_alteracao: item.tipo_alteracao,
          selectedValues: {
            id_alteracao: item.id_alteracao,
            tipo_alteracao: item.tipo_alteracao,
          },
        }))
      },
      sortedFilteredDetailsDental() {
        return [...this.filteredDetailsDental].sort((a, b) => {
          if (
            this.getNomeAlteracao(a.id_alteracao) === "Dente extranumerário" &&
            this.getNomeAlteracao(b.id_alteracao) !== "Dente extranumerário"
          ) {
            return 1
          }
          if (
            this.getNomeAlteracao(b.id_alteracao) === "Dente extranumerário" &&
            this.getNomeAlteracao(a.id_alteracao) !== "Dente extranumerário"
          ) {
            return -1
          }
          return a.num_dente - b.num_dente
        })
      },
      getVariacaoAlteracaoFormatted() {
        return (id) => {
          const variacao = this.faceDental.find((item) => item.id === id)
          return variacao ? ` - (${variacao.name})` : ""
        }
      },
    },
    methods: {
      abrirDialogAdicao(tipo) {
        this.tipoLista = tipo
        this.seuValorDeDialogVisible = true
      },
      async fetchImage(url) {
        const token = localStorage.getItem("jwtToken")

        try {
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })

          if (!response.ok) {
            throw new Error(`Failed to fetch image from ${url}`)
          }

          const blob = await response.blob()
          const src = URL.createObjectURL(blob)

          return { src }
        } catch (error) {
          console.error(error)
          return null
        }
      },

      async enviarDental() {
        try {
          const userId = localStorage.getItem("userId")
          const idExamRoute = this.$route.params.id_exam

          const dadosParaEnviar = {
            id_alteracao: this.alteracao,
            num_dente: this.campoDente ? this.campoDente.trim() : null,
            variacao_alteracao: this.alteracaoFace ? this.alteracaoFace : 0,
            id_alteracao_corrigido: null,
            observacao: this.observacao.trim() !== "" ? this.observacao : null,
            num_dente_corrigido: null,
            id_exam: idExamRoute,
            user: userId,
          }

          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/alt/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(dadosParaEnviar),
            }
          )

          if (response.ok) {
            this.$refs.snackbarRef.showSuccess(
              "Nova alteração cadastrada com sucesso!"
            )
            this.fecharDialogAdicao()
            this.getAchadosDetails()
            this.clearFind()
          } else {
            console.error("Erro ao enviar dados:", response.statusText)
          }
        } catch (erro) {
          this.$refs.snackbarRef.showError(
            "Erro ao adicionar na alteração, tente novamente!"
          )
          this.fecharDialogAdicao()
          this.clearFind()
        }
      },

      async enviarDados() {
        try {
          const userId = localStorage.getItem("userId")
          const idExamRoute = this.$route.params.id_exam

          let id_alteracao = null
          let variacao_alteracao = null

          if (
            this.campoDente &&
            this.campoDente.trim() !== "" &&
            !this.addAchado
          ) {
            id_alteracao = 1
            variacao_alteracao = 3
          } else if (this.campoDente && this.addAchado) {
            const itemSelecionado = this.mapaData.find(
              (item) => item.id_alteracao === this.addAchado
            )
            if (itemSelecionado) {
              id_alteracao = itemSelecionado.id_alteracao
              variacao_alteracao = itemSelecionado.tipo_alteracao
            }
          } else if (!this.campoDente && this.addAchado) {
            const itemSelecionado = this.mapaData.find(
              (item) => item.id_alteracao === this.addAchado
            )
            if (itemSelecionado) {
              id_alteracao = itemSelecionado.id_alteracao
              variacao_alteracao = itemSelecionado.tipo_alteracao
            }
          }

          const dadosParaEnviar = {
            id_alteracao: id_alteracao,
            num_dente: this.campoDente ? this.campoDente.trim() : null,
            variacao_alteracao: variacao_alteracao,
            id_alteracao_corrigido: null,
            observacao: null,
            num_dente_corrigido: null,
            id_exam: idExamRoute,
            user: userId,
          }

          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/alt/`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(dadosParaEnviar),
            }
          )

          if (response.ok) {
            this.$refs.snackbarRef.showSuccess(
              "Nova alteração cadastrada com sucesso!"
            )
            this.fecharDialogAdicao()
            this.getAchadosDetails()
            this.clearFind()
          } else {
            console.error("Erro ao enviar dados:", response.statusText)
          }
        } catch (erro) {
          this.$refs.snackbarRef.showError(
            "Erro ao adicionar na alteração, tente novamente!"
          )
          this.fecharDialogAdicao()
          this.clearFind()
        }
      },
      clearFind() {
        this.campoDente = ""
        this.addAchado = ""
        this.observacao = ""
        this.alteracao = ""
        this.alteracaoFace = ""
      },
      salvarAdicao() {
        // Lógica para salvar a adição
        // ...

        // Feche o diálogo após salvar
        this.fecharDialogAdicao()
      },

      cancelarAdicao() {
        this.clearFind()
        this.fecharDialogAdicao()
      },

      fecharDialogAdicao() {
        // Lógica para fechar o diálogo de adição
        this.seuValorDeDialogVisible = false
      },
      opcoesAlteracao() {
        if (this.editDialogDental) {
          return this.mapaData.filter((item) => item.tipo_alteracao === 2)
        } else if (this.editDialogAnatomico) {
          return this.mapaData.filter((item) => item.tipo_alteracao === 1)
        } else {
          return []
        }
      },
      async loadImages() {
        const id_exam = this.$route.params.id_exam
        const token = localStorage.getItem("jwtToken")

        const image1 = await this.fetchImage(
          `${process.env.VUE_APP_API_URL}/exam/${id_exam}/get_seg_img_auditoria/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        const image2 = await this.fetchImage(
          `${process.env.VUE_APP_API_URL}/exam/${id_exam}/get_original_image/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        this.imageOpen = image2

        const image3 = await this.fetchImage(
          `${process.env.VUE_APP_API_URL}/exam/${id_exam}/get_seg_img_dentes/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )

        this.images = [image1, image2, image3].filter((image) => image !== null)
      },
      getNomeAlteracao(idAlteracao) {
        if (this.mapaData && this.mapaData.length > 0) {
          const matchingItem = this.mapaData.find(
            (item) => item.id_alteracao === idAlteracao
          )

          if (matchingItem && matchingItem.nm_alteracao !== undefined) {
            const nomeAlteracao =
              matchingItem.nm_alteracao.charAt(0).toUpperCase() +
              matchingItem.nm_alteracao.slice(1)
            return nomeAlteracao
          }
        }
        return ""
      },
      async fetchMapaData() {
        try {
          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/mapa/`,
            { headers: { Authorization: `Bearer ${token}` } }
          )

          if (!response.ok) {
            throw new Error("Erro ao obter dados do mapa")
          }

          const mapaData = await response.json()

          this.mapaData = mapaData
          this.listAusent = this.mapaData.filter(
            (item) => item.tipo_alteracao === 3
          )
          this.listDental = this.mapaData.filter(
            (item) => item.tipo_alteracao === 0
          )
          this.listAnatomic = this.mapaData.filter(
            (item) => item.tipo_alteracao === 1
          )
        } catch (error) {
          console.error("Erro ao obter dados do mapa:", error)
        }
      },
      editarItem(item) {
        this.editedItem = { ...item }
        this.editedItemObservacao = item.observacao || null
        this.editDialog = true
        this.editDialogDental = true
        this.editDialogAnatomico = true
      },
      cancelarEdicao() {
        this.editDialog = false
        this.editDialogDental = false
        this.editDialogAnatomico = false
      },
      async salvarEdicao() {
        try {
          const dadosParaEnviar = {
            id_exam: this.editedItem.id_exam,
            id_alteracao_corrigido: this.editedItem.id_alteracao,
            variacao_alteracao: this.editedItem.alteracaoFace
              ? this.editedItem.alteracaoFace
              : 0,
            num_dente: this.editedItem.num_dente,
            observacao:
              this.editedItemObservacao !== ""
                ? this.editedItemObservacao
                : null,
            user: this.editedItem.user,
          }
          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/alt/${this.editedItem.id}/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(dadosParaEnviar),
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao salvar dados atualizados")
          }
          this.getAchadosDetails()
          this.cancelarEdicao()
        } catch (error) {
          console.error("Erro ao salvar edição:", error)
        }
      },
      cancelChanges() {
        // Limpar os campos
        this.numeroDente = ""
        this.alteracao = null
        this.observacao = ""
      },
      async adicionarItem(tipoAlteracao) {
        try {
          const novoItem = {}

          // Abre o diálogo de edição com o novo item
          this.$refs.dialogAdicao.openDialog({
            title: "Adicionar Item",
            item: novoItem,
            tipoAlteracao: tipoAlteracao,
          })
        } catch (error) {
          console.error("Erro ao adicionar item:", error)
        }
      },
      hasAlteration(number) {
        return (
          Array.isArray(this.filteredMapaDataAusente) &&
          this.filteredMapaDataAusente.some((item) => item.num_dente === number)
        )
      },
      hasAlterationDental(number) {
        return (
          Array.isArray(this.filteredDetailsDental) &&
          this.filteredDetailsDental.some((item) => item.num_dente === number)
        )
      },
      getAlterationColor(number) {
        if (!Array.isArray(this.filteredDetailsDental)) {
          return {}
        }

        const alteration = this.filteredDetailsDental.find(
          (item) => item.num_dente === number
        )

        if (
          alteration &&
          Object.prototype.hasOwnProperty.call(
            this.classColors,
            alteration.id_alteracao
          )
        ) {
          const color = this.classColors[alteration.id_alteracao]
          return {
            border: `1px solid ${color}`,
            boxShadow: `0 0 5px ${color}`,
          }
        } else if (alteration) {
          return {
            border: "1px solid red",
            boxShadow: "0 0 5px red",
          }
        } else {
          return {}
        }
      },
      async getAchadosDetails() {
        try {
          this.objDetails = []

          const token = localStorage.getItem("jwtToken")
          const idExamRoute = this.$route.params.id_exam
          const responseDetails = await fetch(
            `${process.env.VUE_APP_API_URL}/exam/${idExamRoute}/get_alt_json/`,
            { headers: { Authorization: `Bearer ${token}` } }
          )

          if (!responseDetails.ok) {
            throw new Error("Erro ao obter detalhes do exame")
          }

          const data = await responseDetails.json()

          this.detailsByIdExam = JSON.parse(data)

          const idSet = new Set()

          this.detailsByIdExam.forEach((item) => {
            if (!idSet.has(item.pk)) {
              let objeto = {}
              Object.assign(objeto, item.fields)
              objeto.id = item.pk
              this.objDetails.push(objeto)
              idSet.add(item.pk)
            }
          })

          await this.addMapaDataToDetails()

          // eslint-disable-next-line no-inner-declarations
          function filterDataByProperty(objDetails, property, value) {
            return objDetails.filter((mapaItem) => mapaItem[property] === value)
          }

          this.filteredMapaDataAusente = filterDataByProperty(
            this.objDetails,
            "tipo_alteracao",
            3
          )

          this.filteredMapaDataAusenteAgrupado =
            this.filteredMapaDataAusente.reduce((acc, item) => {
              if (!acc[item.num_dente]) {
                acc[item.num_dente] = true
              }
              return acc
            }, {})

          this.dentesAusentesAgrupados = Object.keys(
            this.filteredMapaDataAusenteAgrupado
          )

          this.filteredMapaDataAnatomico = filterDataByProperty(
            this.objDetails,
            "tipo_alteracao",
            1
          )
          this.filteredDetailsDental = filterDataByProperty(
            this.objDetails,
            "tipo_alteracao",
            0
          )

          this.filteredDentesExtranumerarios = this.filteredDetailsDental
            .filter((item) => item.nm_alteracao === "Dente extranumerário")
            .map((item) => item.num_dente)
            .sort((a, b) => a - b)
        } catch (error) {
          console.error("Erro ao obter detalhes do exame:", error)
        }
      },

      async addMapaDataToDetails() {
        try {
          await this.fetchMapaData()

          this.objDetails.forEach((detail) => {
            const matchingMapaData = this.mapaData.find(
              (mapaItem) => mapaItem.id_alteracao === detail.id_alteracao
            )

            if (matchingMapaData) {
              detail.tipo_alteracao = matchingMapaData.tipo_alteracao
              detail.nm_alteracao = matchingMapaData.nm_alteracao
            }
          })
        } catch (error) {
          console.error("Erro ao adicionar dados do mapa aos detalhes:", error)
        }
      },
      abrirDialog() {
        console.log("editDialog:", this.editDialog)
        console.log("editDialogDental:", this.editDialogDental)
        console.log("editDialogAnatomico:", this.editDialogAnatomico)
        console.log("seuValorDeDialogVisible:", this.seuValorDeDialogVisible)
      },
      async fetchExamDetails() {
        try {
          const id_exam = this.$route.params.id_exam

          const token = localStorage.getItem("jwtToken")
          const responseDetails = await fetch(
            `${process.env.VUE_APP_API_URL}/pat/${id_exam}/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!responseDetails.ok) {
            throw new Error("Erro ao obter detalhes do exame")
          }

          const examDetails = await responseDetails.json()

          const responseGeneral = await fetch(
            `${process.env.VUE_APP_API_URL}/exam/${id_exam}/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!responseGeneral.ok) {
            throw new Error("Erro ao obter informações gerais do exame")
          }

          const generalDetails = await responseGeneral.json()

          // Use os detalhes do exame para preencher os campos
          this.idade =
            examDetails.idade !== null
              ? examDetails.idade
              : examDetails.idade_corrigido
          this.sexo =
            examDetails.sexo !== null
              ? this.mapSexo(examDetails.sexo)
              : examDetails.sexo_corrigido
          this.tipoDenticao =
            examDetails.tipo_denticao !== null
              ? this.mapTipoDenticao(examDetails.tipo_denticao)
              : this.mapTipoDenticao(examDetails.tipo_denticao_corrigido)
          this.user = examDetails.user

          // Preencha os campos adicionais com os detalhes gerais do exame
          this.imageName = generalDetails.nm_description
          this.dataExame = this.formatarData(generalDetails.dt_received)
          // Segunda solicitação para obter informações da imagem
          const responseImage = await fetch(
            `${process.env.VUE_APP_API_URL}/exam/${id_exam}/get_original_image/`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!responseImage.ok) {
            throw new Error("Erro ao obter a imagem original")
          }

          // Adicione a lógica para exibir a imagem na resposta da segunda requisição
          const imageBlob = await responseImage.blob()
          const imagePath = URL.createObjectURL(imageBlob)

          // Chame o método loadImage com o novo caminho da imagem
          this.loadImage(imagePath)
        } catch (error) {
          console.error("Erro ao obter detalhes do exame:", error)
        }
      },
      mapSexo(value) {
        switch (value) {
          case 1:
            return "Feminino"
          case 2:
            return "Masculino"
          default:
            return "Não Informado"
        }
      },
      mapTipoDenticao(value) {
        switch (value) {
          case 1:
            return "Dentição Permanente"
          case 2:
            return "Dentição Mista"
          case 3:
            return "Dentição Decídua"
          default:
            return "Desconhecido"
        }
      },
      formatarData(data) {
        if (!data) return ""

        const dataObj = addHours(new Date(data), 3)

        const dataFormatada = format(dataObj, "dd/MM/yyyy")
        return dataFormatada
      },
      getImagePath(number) {
        return require(`@/assets/teeth/dente_${number}.png`)
      },
      async exportarPdf() {
        this.loading = true

        const timeoutId = setTimeout(() => {
          this.loading = false
        }, 4000)

        try {
          const id_exam = this.$route.params.id_exam
          const token = localStorage.getItem("jwtToken")

          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/exam/${id_exam}/get_report_pdf/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao salvar dados atualizados")
          }
          clearTimeout(timeoutId)

          this.$refs.snackbarRef.showSuccess("Preparando relatório!")
          const blob = await response.blob()
          saveAs(blob, "relatório.pdf")
        } catch (error) {
          this.$refs.snackbarRef.showError(
            "Erro ao gerar relatório, tente novamente!"
          )
        }
      },
      initializeHistory() {
        this.history = []
        this.historyIndex = -1
        this.addToHistory()
      },
      redirectToPageImage() {
        this.$router.push({ path: this.imageOpen })
      },
      addToHistory() {
        // Adiciona o estado atual ao histórico
        const objectsState = this.canvas.toDatalessObject()
        this.history.push(objectsState)
        this.historyIndex++
      },

      undo() {
        // Desfaz a última ação de desenho no Fabric.js
        if (this.historyIndex > 0) {
          this.historyIndex--
          const previousState = this.history[this.historyIndex]
          this.loadCanvasState(previousState)
        }
      },

      redo() {
        // Refaz a última ação de desenho desfeita no Fabric.js
        if (this.historyIndex < this.history.length - 1) {
          this.historyIndex++
          const nextState = this.history[this.historyIndex]
          this.loadCanvasState(nextState)
        }
      },

      loadCanvasState(state) {
        // Carrega o estado do canvas a partir do histórico
        this.canvas.loadFromDatalessObject(state, () => {
          this.canvas.renderAll()
        })
      },
      increaseImageSize() {
        // Lógica para aumentar o tamanho da imagem no Fabric.js
        const scaleFactor = 1.1 // Ajuste conforme necessário
        const activeObject = this.canvas.getActiveObject()

        if (activeObject && activeObject.isType("image")) {
          activeObject.scaleX *= scaleFactor
          activeObject.scaleY *= scaleFactor
          this.canvas.renderAll()
        }
      },

      decreaseImageSize() {
        // Lógica para reduzir o tamanho da imagem no Fabric.js
        const scaleFactor = 0.9 // Ajuste conforme necessário
        const activeObject = this.canvas.getActiveObject()

        if (activeObject && activeObject.isType("image")) {
          activeObject.scaleX *= scaleFactor
          activeObject.scaleY *= scaleFactor
          this.canvas.renderAll()
        }
      },
      loadImage(imageUrl) {
        fabric.Image.fromURL(imageUrl, (img) => {
          const initialLeft = (this.canvas.width - img.width) / 2
          const initialTop = (this.canvas.height - img.height) / 2

          img.set({
            left: initialLeft,
            top: initialTop,
            hasControls: true,
            selectable: true,
          })

          this.canvas.add(img)
          this.canvas.setActiveObject(img)
          this.canvas.renderAll()
        })
      },
      zoomIn() {
        // Lógica para zoom in no Fabric.js
        this.canvas.setZoom(this.canvas.getZoom() * 1.1)
        this.canvas.renderAll()
      },

      zoomOut() {
        // Lógica para zoom out no Fabric.js
        this.canvas.setZoom(this.canvas.getZoom() / 1.1)
        this.canvas.renderAll()
      },

      toggleMove() {
        // Lógica para ativar/desativar o movimento no Fabric.js
        this.isMoving = !this.isMoving
        this.canvas.isDrawingMode = false // Desativa o modo de desenho
      },

      openTextDialog() {
        this.dialog = true
      },

      closeTextDialog() {
        this.dialog = false
      },

      addText() {
        this.dialog = false

        // Lógica para adicionar texto no Fabric.js
        const text = new fabric.IText(this.textToAdd, {
          left: 10,
          top: 10,
          fill: this.textColor,
          fontSize: this.textSize,
        })

        this.canvas.add(text)
      },

      addCircle() {
        // Lógica para adicionar um círculo no Fabric.js
        const circle = new fabric.Circle({
          radius: 20,
          fill: "red",
          left: 10,
          top: 10,
        })
        this.canvas.add(circle)
      },

      addSquare() {
        // Lógica para adicionar um quadrado no Fabric.js
        const square = new fabric.Rect({
          width: 30,
          height: 30,
          fill: "blue",
          left: 10,
          top: 10,
        })
        this.canvas.add(square)
      },

      addRectangle() {
        // Lógica para adicionar um retângulo no Fabric.js
        const rectangle = new fabric.Rect({
          width: 40,
          height: 30,
          fill: "green",
          left: 10,
          top: 10,
        })
        this.canvas.add(rectangle)
      },

      clearAll() {
        // Lógica para limpar apenas os objetos adicionados (não a imagem)
        const objectsToRemove = this.canvas
          .getObjects()
          .filter((obj) => !obj.isType("image"))

        objectsToRemove.forEach((obj) => {
          this.canvas.remove(obj)
        })

        this.canvas.renderAll()
      },
      downloadCanvas() {
        // Obtém a URL da imagem gerada a partir do estado atual do canvas
        const imageURL = this.canvas.toDataURL({
          format: "png", // ou outro formato desejado
          quality: 1.0, // 1.0 significa qualidade máxima
        })

        // Cria um elemento de link temporário
        const link = document.createElement("a")

        // Define o URL do link para a URL da imagem
        link.href = imageURL

        // Define o atributo de download para o nome do arquivo desejado
        link.download = "canvas_snapshot.png" // ou outro nome de arquivo desejado

        // Simula um clique no link para iniciar o download
        link.click()
      },
      toggleDrawMode() {
        // Ativa ou desativa o modo de desenho no Fabric.js
        this.isMoving = false
        this.canvas.isDrawingMode = !this.canvas.isDrawingMode

        if (this.canvas.isDrawingMode) {
          this.canvas.discardActiveObject()
        }
      },
      editarPaciente() {
        if (!this.editando) {
          this.idadeEditado = this.idade
          this.sexoEditado = this.sexo
          this.tipoDenticaoEditado = this.tipoDenticao
          this.dataExameEditado = this.dataExame
        } else {
          const idadeAlterada = this.idade !== this.idadeEditado
          const sexoAlterado = this.sexo !== this.sexoEditado
          const tipoDenticaoAlterado =
            this.tipoDenticao !== this.tipoDenticaoEditado
          const dataExameAlterada = this.dataExame !== this.dataExameEditado

          if (
            idadeAlterada ||
            sexoAlterado ||
            tipoDenticaoAlterado ||
            dataExameAlterada
          ) {
            this.idade = this.idadeEditado
            this.sexo = this.sexoEditado
            this.tipoDenticao = this.tipoDenticaoEditado
            this.dataExame = this.dataExameEditado

            const dadosAtualizados = {
              id_exam: this.$route.params.id_exam,
              sexo_corrigido: this.sexo ? this.sexoEditado.value : this.sexo,
              idade_corrigido: parseInt(this.idade),
              tipo_denticao_corrigido: this.tipoDenticao
                ? this.tipoDenticaoEditado.value
                : this.tipoDenticao,
              user: this.user,
            }

            this.enviarDadosAtualizados(dadosAtualizados)
          }
        }

        // Alterna entre os modos de edição e salvamento
        this.editando = !this.editando
      },

      async enviarDadosAtualizados(dadosAtualizados) {
        const id_exam = this.$route.params.id_exam
        try {
          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/pat/${id_exam}/`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
              body: JSON.stringify(dadosAtualizados),
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao salvar dados atualizados")
          }
          this.fetchExamDetails()
          console.log("Dados atualizados com sucesso!")
        } catch (error) {
          console.error("Erro ao salvar dados atualizados:", error)
        }
      },
      cancelarEdicaoPat() {
        this.editando = false
      },
      compartilhar() {
        console.log("Compartilhamento")
      },
      async removerItem(item) {
        this.removeItem = item.id
        try {
          const token = localStorage.getItem("jwtToken")
          const response = await fetch(
            `${process.env.VUE_APP_API_URL}/alt/${this.removeItem}/`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (!response.ok) {
            throw new Error("Erro ao deletar achado")
          }
          this.$refs.snackbarRef.showSuccess("Achado deletado com sucesso!")
          this.getAchadosDetails()
        } catch (error) {
          this.$refs.snackbarRef.showError("Erro ao deletar, tente novamente!")
        }
      },
      async updateStatus() {        
        try {
          const id_exam = this.$route.params.id_exam
          const token = localStorage.getItem("jwtToken")
          await axios.patch(`${process.env.VUE_APP_API_URL}/exam/${id_exam}/`, {
            sg_status: 'R',
          },{
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`
            }
          })
          this.$refs.snackbarRef.showSuccess("Laudo revisado com sucesso!")
        } catch(e) {
          console.error('Erro ao atualizar status: ', e)
          this.$refs.snackbarRef.showError("Erro ao revisar laudo!")
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import "@/style/main.scss";
  .canvas-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid black;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    height: 100%;
  }

  canvas {
    width: 100%;
    height: 100%;
  }

  .square {
    position: relative;
    width: 180%;
    height: 120%;
    background-color: transparent;
    border: 2px solid red;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  .alteration-rectangle {
    border: 1px solid red;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    margin: -10px;
    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);
    border-radius: 5px;
  }
  .hover-click-effect:hover {
    cursor: pointer;
  }
  .btn-reviewd-wrap {
    display: flex;
    justify-content: end;
    padding: 0 1.75rem 1.5rem 0;
  }
</style>
