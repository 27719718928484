<script setup>
import { ref, computed, watch, onMounted, onUnmounted } from 'vue'
import useMapaStore from '@/stores/mapa'
import { useRoute } from 'vue-router'
import useFindingsDetailsStore from "@/stores/findings"
import { postAlteracao } from '@/services/exameDetails'
import snackbarNotification from '@/components/snackbarNotification.vue'

const storeMapa = useMapaStore()
const storeFindings = useFindingsDetailsStore()
const route = useRoute()
const snackbarRef = ref(null)
const showTeeth = ref(false)

const inputValue = ref('')
const dentesArray = ref([])
const achado = ref(null)
const idAchado = ref(null)

const examId = route.params.id_exam

// Função para processar dentes/face
function processInput(value) {
  value = value.trim()

  const partes = value.split('-').map((part) => part.trim())

  // Verifica se a primeira parte começa com "d"
  if (partes[0] && partes[0].toLowerCase().startsWith('d')) {
    const regex = /\d{2}[a-zA-Z]*/g
    const matches = partes[0].match(regex) || []

    // Atualiza o array de dentes
    dentesArray.value = matches.map((match) => {
      const dente = match.slice(0, 2)
      const face = match.slice(2)
      return { dente, face }
    })
  } else {
    dentesArray.value = [] // Reseta o array de dentes caso não seja um valor válido
  }
}

// Computed para formatar o array de dentes
const formattedDentes = computed(() => {
  let result = ''

  if (Array.isArray(dentesArray.value) && dentesArray.value.length > 0) {
    result += `Dente(s): `
    result +=
      dentesArray.value
        .map(({ dente, face }) =>
          `${dente}${face ? `[${face.toUpperCase()}]` : ''}`
        )
        .join('/ ') + ' - '
  }

  return result
})

// Computed para filtrar os achados
const itensFiltrados = computed(() => {
  return Array.isArray(storeMapa.getMapa)
    ? storeMapa.getMapa.filter((item) => item.atalho === achado.value?.toString())
    : []
})

const dataAlt = computed(() => {
  // Caso não haja dentes informados, mas o achado esteja presente
  if (dentesArray.value.length === 0 && idAchado.value) {
    return [{
      id_alteracao: idAchado.value,
      num_dente: null, // Sem dente informado
      face: '', // Sem face
      id_exam: examId,
      user: localStorage.getItem("userId"),
    }]
  }

  // Caso haja dentes informados e um achado
  if (Array.isArray(dentesArray.value) && dentesArray.value.length > 0 && idAchado.value) {
    return dentesArray.value.map(({ dente, face }) => ({
      id_alteracao: idAchado.value,
      num_dente: parseInt(dente, 10),
      variacao_alteracao: face || '',
      id_exam: examId,
      user: localStorage.getItem("userId"),
    }))
  }

  return [] // Retorna um array vazio se nenhum dos casos for atendido
})


// Observa o input para atualizar o achado em tempo real
watch(inputValue, (newValue) => {
  const valueInput = newValue.trim()
  const regex = /- (\d+)/
  const match = valueInput.match(regex)

  if (valueInput.toLowerCase().startsWith('d') && !valueInput.startsWith('Dente')) {
    if (valueInput.length > 2) {
      showTeeth.value = true
    }
    processInput(valueInput)
  } else if (regex.test(valueInput)){
    achado.value = parseInt([match[1]], 10) || null
  } else {
    achado.value = parseInt(valueInput, 10) || null
  }
})

// Manipulador de eventos de teclado
function handleKeyDown(event) {
  if (event.key === 'ArrowDown' && showTeeth.value) {
    selectDente()
  } else if (event.key === 'ArrowDown' && !showTeeth.value) {
    selectAchado(itensFiltrados.value[0])
  }
}

const selectDente = () => {
  showTeeth.value = false
  inputValue.value = formattedDentes.value
}

// Atualiza o input ao clicar em um item da lista
function selectAchado(item) {
  idAchado.value = item.id_alteracao
  inputValue.value = `${formattedDentes.value} ${item.nm_alteracao}`
  achado.value = null
}

const sendRequet = () => {
  postAlteracao(dataAlt.value)
  .then(() => {
    snackbarRef.value.showSuccess("Achado cadastrado com sucesso!")
    storeFindings.fetchFindings(examId)
  })
  .catch(error => {
    console.log('Error: ', error)
    snackbarRef.value.showError("Erro ao cadastrar achado!")
  })

  inputValue.value = ''
  dentesArray.value = []
}

// Registra e remove eventos globais no ciclo de vida do componente
onMounted(() => {
  document.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  document.removeEventListener('keydown', handleKeyDown)
})
</script>

<template>
  <div class="cmp-input-achados">
    <snackbarNotification ref="snackbarRef" />
    <div class="cmp-input-achados__input">
      <input
        v-model="inputValue"
        type="text"
        class=""
        placeholder="+ Incluir novo achado"
        @keydown.enter="sendRequet"
      />

      <button 
        class="cmp-input-achados__button-save"
        @click="sendRequet"
      >
        <v-icon 
          name="la-save" 
          scale="1.25"
        />
      </button>
    </div>
    
    <div 
      v-if="showTeeth"
      class="cmp-input-achados__dente"
    >
      <p @click="selectDente">
        {{ formattedDentes }}
      </p>
    </div>

    <ul 
      v-if="itensFiltrados && itensFiltrados.length"
      class="cmp-input-achados__achado"
    >
      <li
        v-for="(item, index) in itensFiltrados"
        :key="index"
        @click="selectAchado(item)"
      >
        {{ item.nm_alteracao }}
      </li>
    </ul>
  </div>
</template>



<style lang="scss" scoped>
.cmp-input-achados {
  &__input {
    border: 1px solid #000;
    border-radius: .25rem;
    margin-bottom: .75rem;
    width: 100%;
    position: relative;

    input {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25rem;
      text-align: left;
      color: #9C9C9C;
      padding: .6875rem .5rem;
      width: 100%;

      &:focus::placeholder {
        color: transparent;
      }
    }
  }

  &__button-save {
    position: absolute;
    right: 1rem;
    top: .5rem;
  }

  &__achado, &__dente {
    border: .125rem solid #C0C0C0;
    border-radius: .5rem;
    display: inline-block;
    background-color: #f3f3f3;
    width: 300px;
    position: absolute;
    left: 4rem;
    top: 3rem;
    z-index: 3;

    li, p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.465rem;
      letter-spacing: .0063rem;
      text-align: left;
      color: #000;
      list-style: none;
      background-color: #fff;
      margin: .5rem 0;
      padding: .35rem;

      &:hover {
        background-color: #A8A7B9;
        color: #fff;
        cursor: pointer;
      }
    }
  }
}
</style>