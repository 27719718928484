<script setup>
import { ref, computed } from 'vue';
import { useRoute } from 'vue-router'
import useExamStore from '@/stores/exam'
import useFindingsDetailsStore from '@/stores/findings'
import { changeTypeTeeth } from '@/services/exameDetails'
import snackbarNotification from "../snackbarNotification.vue"

const storeExam = useExamStore()
const storeFindings = useFindingsDetailsStore()

const route = useRoute()

const snackbarRef = ref(null)

const userId = localStorage.getItem("userId")
const examId = route.params.id_exam

const tooltipText = 'Clique no elemento do odontograma para inserir ou remover'

const teethOptionsSelect = ref(storeExam.mapTipoDenticao)

const teethOptions = ['Dentição Permanente', 'Dentição Mista', 'Dentição Decídua', 'Desconhecido']

const teethOptionsFilter = computed(() => {
  return teethOptions.filter(item => item !== storeExam.mapTipoDenticao);
});

const dentesAusentesAgrupados = computed(() => {
  const findings = storeFindings.getFindingsDetails
  
  const teethOut = Object.keys(findings)
    .filter(key => findings[key].fields.id_alteracao === 1)
    .map(key => findings[key].fields.num_dente)
  
  storeFindings.setTeethOut(teethOut)
  return teethOut
})

const requestData = () => {
  let type = null

  switch (teethOptionsSelect.value) {
    case "Dentição Permanente":
      type = 1
      break
    case "Dentição Mista":
      type = 2
      break
    case "Dentição Decídua":
      type = 3
      break
    case "Desconhecido":
      type = 0
      break
  }

  return {
    id_exam: examId,
    tipo_denticao: type,
    user: userId,
  }
}

const changeTeethType = () => {
  changeTypeTeeth(examId, requestData())
    .then(() => {
      snackbarRef.value.showSuccess("Tipo de dentição alterado com sucesso!")
      storeFindings.fetchFindings(examId)
    })
    .catch(() => {
      snackbarRef.value.showError("Erro ao alterar tipo de dentição.")
    })
}

</script>


<template>
  <div class="cmp-teeth-type">
    <snackbarNotification ref="snackbarRef" />
    <h3 class="cmp-teeth-type__title">Tipo de Dentição (com ausentes e extranum.)</h3>
    <div class="cmp-teeth-type__items">
      <div class="cmp-teeth-type__teeth-permanent">
        <select v-model="teethOptionsSelect" @change="changeTeethType">
          <option :value="storeExam.mapTipoDenticao">
            {{ storeExam.mapTipoDenticao }}
          </option>
          
          <option
            v-for="(item, i) in teethOptionsFilter"
            :key="i"
            :value="item"
          >
            {{ item }}
          </option>
        </select>
      </div>
      
      <div class="cmp-teeth-type__teeth-out">
        <v-tooltip :text="tooltipText" location="top">
          <template v-slot:activator="{ props }">
            <p v-bind="props">
              {{ dentesAusentesAgrupados.length }} Elementos ausentes: {{ dentesAusentesAgrupados.join(', ') }}
            </p>
          </template>
        </v-tooltip>
      </div>
      <div class="cmp-teeth-type__num-ext">
        <p>Nenhum elemento extranumerário</p>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @import '_teeth-type.scss'
</style>
